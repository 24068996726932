body {
    letter-spacing: -0.5px;
}

h1 {
    letter-spacing: -2px;
}
.lead {
    letter-spacing: -1.11px;
    font-size: 2rem;
        line-height: 2.2rem;
}
h2 {
    letter-spacing: 0px;
    font-size: 1.75rem;
    padding-bottom: 0.5rem;

}

ul {
    padding-left: 1rem;
}
li {
    padding-bottom: 0.5rem;
    line-height: 1.25rem;
}

.video-container {
position: relative;
padding-bottom: 56.25%;
padding-top: 30px; height: 0; overflow: hidden;
margin-top:2rem
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Space out content a bit */
// body {
//   padding-top: 20px;
//   padding-bottom: 20px;
// }

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-right: 15px;
  padding-left: 15px;
}

/* Custom page header */
.header {
  padding-bottom: 20px;
  // border-bottom: 1px solid #e5e5e5;
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
}

/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    max-width: 950px;
  }
}
.container-narrow > hr {
  margin: 30px 0;
}

/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
}
.jumbotron .btn {
  padding: 14px 24px;
  font-size: 21px;
}

/* Supporting marketing content */
.marketing {
  margin: 20px 0;
}
.marketing p + h4 {
  margin-top: 28px;
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the padding we set earlier */
  .header,
  .marketing,
  .footer {
    padding-right: 0;
    padding-left: 0;
  }
  /* Space out the masthead */
  .header {
    margin-bottom: 30px;
  }
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}

// /* Non-bootstrap related custom css */
//
// /* GLOBAL STYLES
// -------------------------------------------------- */
// /* Padding below the footer and lighter body text */
//
// .grey-bg {
//     background-color: #EAEAEC;
// }
//
// body {
//   color: #5a5a5a;
//   font-family: "MuseoSans_500", "Helvetica Neue", Helvetica, Arial, sans-serif
//
// }
//
// .menu-item {
//     display: inherit;
//     padding-left: 1rem;
// }
//
// /* CUSTOMIZE THE NAV */
//
// .navbar-brand {
//     max-height: 70px;
// }
//
// .navbar-nav {
//     display: inline-block;
//     font-size: 23px;
//     font-weight: 100;
//         // padding-top: 1.3rem;
// }
//
// .nav-link {
//     color: #000;
//
// }
//
// .nav-link:hover {
//     text-shadow: 1px 1px 2px rgba(0,0,255,0.2);
// }
//
//
// .nav-menu {
//     text-align: center;
//     width: 100%;
//     // padding-top: 20px;
//     background-color: rgba(255,255,255,0.5)
// }
//
// .flex-vertical-center {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }
//
// .nav-menu-bottom {
//     position: absolute;
//     bottom: 0px;
//     // left: 20px;
//     width: 100%;
// }
// .banner-image {
//     background-image: linear-gradient(  rgba(255,255,255, 0.7), rgba(255,255,255,0.4) ), url('../glenn-carstens-peters-203007.jpg'); //linear-gradient(  rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8) )
//     background-repeat:no-repeat;
//     background-size:cover;
//     background-position:center;
//     // background-position: center;
//     // -o-background-size:100% auto;
//     // -webkit-background-size:100% auto;
//     // -moz-background-size:100% auto;
//     // background-size:100% auto;
//     height: 611px;
//     width: 100%;
//     position: relative;
//     color: #111;
// }
//
// .banner-content {
//
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }
//
// .banner-caption {
//     color: #FFF;
//     text-shadow: 0px 0px 2px rgba(0,0,0,0.8);
//     position: absolute;
//     bottom: 1rem;
//     text-align: center;
//     width: 100%;
// }
//
// h2 {
//     font-weight:100;
//     font-size: 54px;
//     text-shadow: 0px 0px 20px rgba(255,255,255,0.5);
// }
//
// .text-center {
//     text-align: center;
// }
//
// .text-right {
//     text-align: right;
// }
//
// .icon-background {
//     color: #fff;
// }
//
// .fa.fa-6 {
//     font-size: 20rem;
// }
//
// .marketing {
//     padding-top: 3rem;
// }
//
// .marketing .marketing-item {
//   margin-bottom: 1.5rem;
//   text-align: center;
// }
// .marketing h3 {
//   font-weight: normal;
//   margin-top: 1.5rem;
// }
// .marketing .marketing-item p {
//   margin-right: .75rem;
//   margin-left: .75rem;
// }
//
//
// /* Featurettes
// ------------------------- */
//
// .featurette-divider {
//   margin: 2rem 0; /* Space out the Bootstrap <hr> more */
// }
//
// /* Thin out the marketing headings */
// .featurette-heading {
//   font-weight: 300;
//   line-height: 1;
//   letter-spacing: -.05rem;
// }
//
// footer {
//     padding-top: 1rem;
// }
//
// /* RESPONSIVE CSS
// -------------------------------------------------- */
//
// @media (min-width: 40em) {
//   /* Bump up size of carousel content */
//   .carousel-caption p {
//     margin-bottom: 1.25rem;
//     font-size: 1.25rem;
//     line-height: 1.4;
//   }
//
//   .featurette-heading {
//     font-size: 50px;
//   }
// }
//
// @media (min-width: 62em) {
//   .featurette-heading {
//     margin-top: 2rem;
//   }
// }
